import { Switch } from "@headlessui/react";
import PropTypes from "prop-types";
import React from "react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Toggle({ label, value, onChange, toggleColor, small, className }) {
	return (
		<Switch.Group as="div" className={`flex items-center ${className ? className : ""}`}>
			<Switch.Label
				as="span"
				className={classNames(small ? "mr-1 md:ml-1" : "mr-3 md:ml-3", "flex-1 md:order-2 md:flex-none")}
			>
				<span className={classNames(small ? "text-xs " : "text-sm ")}>{label}</span>
			</Switch.Label>
			<Switch
				checked={value}
				onChange={onChange}
				className={classNames(
					value && !toggleColor ? "bg-brand" : "bg-gray-200",

					small ? "h-4 w-7 border" : "h-6 w-11 border-2",
					"order-transparent relative inline-flex shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
				)}
				style={value && toggleColor ? { background: toggleColor } : null}
			>
				<span
					aria-hidden="true"
					className={classNames(
						// eslint-disable-next-line no-nested-ternary
						value ? (small ? "translate-x-3" : "translate-x-5") : "translate-x-0",
						toggleColor ? "" : "bg-white",
						small ? "h-3 w-3" : "h-5 w-5",
						"pointer-events-none inline-block transform rounded-full shadow ring-0 transition duration-200 ease-in-out"
					)}
					style={(toggleColor && { background: toggleColor }, small && { marginTop: 1 })}
				/>
			</Switch>
		</Switch.Group>
	);
}

Toggle.propTypes = {
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool.isRequired,
	toggleColor: PropTypes.string,
	small: PropTypes.bool,
	className: PropTypes.string,
};

Toggle.defaultProps = {
	toggleColor: null,
	small: null,
	className: null,
};
