import { EyeIcon, PencilIcon } from "@heroicons/react/outline";
import { graphql, Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlurbSphere from "~components/features/BlurbSphere";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import FaqItem from "~components/features/FaqItem";
import Grid from "~components/common/Grid";
import ProvenExpert from "~components/features/ProvenExpert";
import Layout from "~components/layouts/Layout";
import CtaSection from "~components/sections/Cta";
import PageHeader from "~components/sections/PageHeader";
import AboPlans from "~components/sections/PricingPlans/AboPlans";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import H4 from "~components/typography/H4";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import WebdesignPlansAlt from "~components/sections/PricingPlans/WebdesignPlansAlt";

const faq = [
	{
		title: "Zahlung",
		questions: [
			{
				title: "In welchem Intervall muss ich bezahlen?",
				text: "Gezahlt werden kann immer im Voraus für zwölf Monate oder monatlich.",
			},
			{
				title: "Ab wann muss ich bezahlen?",
				text: "Nachdem wir die Website fertig programmiert haben und online geschaltet habe.",
			},
			{
				title: "Wie kann ich bezahlen?",
				text: "Wir bevorzugen Lastschrift über http://gocardless.com/. Du kannst aber auch einen Daueraftrag einrichten, oder monatlich die Rechnung händisch bezahlen.",
			},
			{
				title: "Bekomme ich eine Rechnung?",
				text: "Ja, Du bekommst immer vorab eine Rechnung mit ausgewiesener Mehrwert-/Umsatzsteuer.",
			},
		],
	},
	{
		title: "Design, Inhalte & Funktionen",
		questions: [
			{
				title: "Welche Bilder werden eingebaut?",
				text: "Wir schlagen bestimmte Stockfotos vor. Solltest Du eigene Fotos haben, bauen wir diese gerne für Dich ein.",
			},
			{
				title: "Woher kommen Die Texte für die Website?",
				text: "Einfache Texte lassen wir ohne zusätzliche Kosten für Dich schreiben. Alles weitere wie SEO- oder Fachtexte können gegen einen Aufpreis beauftragt werden oder Du lieferst uns  diese als digitale Datei.",
			},
			{
				title: "Wie sieht das Design aus?",
				text: "Wir erstellen ein individuelles Design Hand in Hand in Absprache mit Dir. Alternativ bauen wir die Website auch anhand einer digitalen Vorlage.",
			},
			{
				title: "Brauche ich ein Logo?",
				text: "Ja. Solltest Du kein Logo haben, dann gestalten wir bei Bedarf Deine komplette Corporate Identitiy. Dabei definieren wir gemeinsam wie Dein komplette Auftreten auszusehen hat. Es geht dabei um Typografie, Farben, Bilderwelten und vieles mehr.",
			},
			{
				title: "Welche Funktionen kann ich erwarten?",
				text: "Ein einfaches Kontaktformular, eine Galerie und ein Slider sind inbegriffen. Außerdem werden automatisch Daten aus Google My Business, wie Deine Öffnungszeiten, Adresse, Telefonnummer und die fünf relevantesten Rezensionen automatisch wöchentlich auf Deiner Website aktualisiert. Zusätzlich richten wir für Dir Google Analytics mit einem DSGVO konformen Cookie-Banner ein.",
			},
			{
				title: "Ich brauche extra Funktionen. Kann ich diese buchen?",
				text: "Solltest Du weitere Funktionen wie z.B. ein Reservierungstool benötigen, können wir dies gegen Aufpreis einrichten. Die Kosten müssten wir allerdings gemeinsam abwägen, da jeder seine individuellen Vorstellungen hat.",
			},
			{
				title: "Was ist das besondere an der Website?",
				text: "Die Website wird auf dem neusten technologischen Standard einer JAMStack-Website sein. Die Vorteile dieser Websiten sind extrem kurze Ladezeiten und eine extrem hohe Sicherheit.",
			},
		],
	},
	{
		title: "Service, Werbung und SEO",
		questions: [
			{
				title: "Was ist im Service inbegriffen?",
				text: "Wir passen jegliche Texte und Bilder auf Deiner Website während der Laufzeit an. Dies könnten Preisänderungen, Leistungsänderungen, Angebote oder einfach nur andere Fotos sein. Jegliche rechtlichen Änderungen wie z.B. die DSGVO haben wir auf dem Schirm und passe diese für Dich an. Aber auch Änderungen am Layout sind möglich.",
			},
			{
				title: "Wird SEO für die Seite gemacht?",
				text: "Die Website wird nach den neusten semantischen Strukturen aufgebaut und wir garantieren Dir extrem kurze Ladezeiten. Zusätzlich bauen wir Strukturierte-Daten zu Deinem Unternehmen und Rezensionen ein.",
			},
			{
				title: "Wird Werbung für die Website gemacht?",
				text: "Nein. Aber wenn Du möchtest können wir gerne ein monatliches Werbebudget absprechen und planen eine Google Ads und Facebook/Instagram Kampagne. Wir behalten immer 10 % des Werbebudgets für den Aufwand ein.",
			},
		],
	},
	{
		title: "Hosting",
		questions: [
			{
				title: "Wer übernimmt das Hosting?",
				text: "Wir pflegen und kümmeren uns um das Hosting. Die Hosting-Kosten sind in dem monatlichen Preis inbegriffen.",
			},
			{
				title: "Kostet das Hosting oder die Webadresse extra?",
				text: "Nein. Das Hosting der Website ist kostenfrei. Einfache „.de“, „.com“, „.info“, „.net“ oder „.org“ Domains sind ebenfalls kostenfrei. Neue Domains wie zum Beispiel „.restaurant“, „.reise“, oder „.pizza“ müssen individuell geprüft werden, da diese im Preis stark schwanken.",
			},
			{
				title: "Welche Webadresse wird benutzt?",
				text: "Du kannst Dir eine freie Domain aussuchen. Solltest Du schon im Besitz einer Domain sein, dann richte ich Dir diese ein.",
			},
			{
				title: "Bekomme ich eine E-Mailadresse?",
				text: "Ja. Passend zu Deiner Domain, erhälst Du ein IMAP-Postfach mit 5GB Speicherplatz.",
			},
			{
				title: "Wo wird gehostet?",
				text: "Gehostet wird auf netlify. Dadurch wird Deine Website dezentral auf der ganzen Welt blitzschnell erreichbar sein. Die Domain liegt bei netcup.",
			},
		],
	},
	{
		title: "Rechtliches",
		questions: [
			{
				title: "Gibt es einen Vertrag?",
				text: "Es wird einen ganz normalen Vertrag für die Website geben, auf dem alle hier beschriebenen Konditionen definiert sind.",
			},
			{
				title: "Wann kann ich kündigen?",
				text: "Du kannst immer sofort kündigen. Die Website wird automatisch gelöscht.",
			},
			{
				title: "Wie kann ich kündigen?",
				text: "Eine einfache Mail reicht.",
			},
			{
				title: "Was passiert wenn ich nicht mehr zahlen kann?",
				text: "Wir haben immer ein offenes Ohr. Sollte es Schwierigkeiten geben, dann bitte ich Dich mit uns zu sprechen, um gemeinsam eine Lösung zu finden. Ein unkommentierter Zahlungsausfall führt zur Kündigung.",
			},
			{
				title: "Gibt es andere Pakete?",
				text: "Nein, die Pakete sind so geformt, dass diese von der Funktionalität und dem Inhalt durch die Zusatzpakete nach oben skalieren können. Enterprise-Lösungen bieten wir auf Anfrage an.",
			},
		],
	},
	{
		title: "Sonstiges",
		questions: [
			{
				title: "Was ist wenn ich unzufrieden bin?",
				text: "Dann zahlst Du nichts! Da die erste Zahlung erst nach dem online Schalten und durch Deine finale Freigabe fällig ist, bist Du auf der sicheren Seite. Sollte es Probleme während der Laufzeit geben, kannst Du ohne Schwierigkeiten kündigen, da es keine Mindestvertragslaufzeit gibt.",
			},
			{
				title: "Wie lange dauert die Erstellung der Website?",
				text: "Das ist ganz individuell und abhängig von Deiner Zuarbeit. Sobald alles bezüglich des Logos und der Inhalte geklärt ist, dauert die Entwicklung der Website ca. zwei Wochen.",
			},
			{
				title: "Gibt es einen Haken?",
				text: "Nein. Wir möchten einen ehrlichen und guten Service anbieten. Wir sind davon überzeugt, dass sobald Du uns kennengelernt hast, wir eine längere und intensive Zusammenarbeit haben werden. Aus diem Grund nehmen wir keine hohen Initialzahlungen und kalkuliere mit fairen Preisen.",
			},
		],
	},
];

export default function Page({ data, location }) {
	const keyword = "Website Abo";
	const title = "Website Abo ▷ Vom Programmierer zum Fixpreis";
	const description =
		"Dein Website-Abo ab 60 € pro Monat für erfolgreiche Internetseiten. Wir planen und gestalten Deine Website individuell. ▻ Geringe Rate & geringe Investition!";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{ name: "Website Abo", to: "/leistungen/website-abo/" },
				]}
			/>
			<PageHeader title="Website Abo: Deine Website ab 60,- € im Monat">
				<div className="my-4 max-w-xl md:mx-auto">
					<P>
						Sichere Dir Deine komplett individuelle Website zu einem monatlichem Fixpreis. Das Paket beinhaltet ein{" "}
						<strong>komplettes Service-Paket während der Laufzeit</strong> und keine versteckten Kosten. Du sparst Dir
						hohe Investitionskosten und musst Dir keine Gedanken machen, falls Du etwas an der Website ändern möchtest.
					</P>
				</div>
				<div className="mb-4">
					<ProvenExpert />
				</div>
				<div className="my-8 flex flex-row space-x-3 md:justify-center">
					<Button to="/leistungen/website-abo/anfragen/" text="Anfragen" />
					<Button anchor="/leistungen/website-abo/#preise" text="Preise" />
				</div>
			</PageHeader>

			<WebdesignPlansAlt
				title={keyword}
				description={description}
				link={`${location.pathname}anfragen/`}
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
				keyword={keyword}
				image={data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				aboToggle={true}
			/>

			<Container id="konzept">
				<Grid cols={2} className="mb-8 md:mb-32" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Von A bis Z" number="02" className="mb-5 " />
						<H2 display className="mb-5 font-display">
							Was ist ein Website Abo?
						</H2>
						<P>
							Was ist ein Website-Abo? Das Abo-Modell unterscheidet sich vom klassischen Auftragsmodell in der Hinsicht,
							dass wir Dich langfristig begleiten und in allen Digitalisierung-Fragen unterstützen. Du zahlst eine
							geringe monatliche Gebühr und bekommst eine Website in individuellem Webdesign und angepassten Funktionen.
							Du musst kein technisches Verständnis mitbringen und musst auch nicht an der Webseite tüfteln. Wir
							erstellen das Design, kümmern uns um die technischen Einrichtungen und achten darauf, dass alles
							ordentlich gehostet wird. Selbstverständlich kannst Du auch Deine eigene Domain aussuchen.
						</P>
					</div>
					<div className="bg-pattern p-3">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_header.jpg"
							alt="Website Abo Konzept | Service an Website"
							title="Website Abo Konzept | Service an Website"
							placeholder="blurred"
							aspectRatio={16 / 9}
						/>
					</div>
				</Grid>
				<Grid cols={2} className="mb-8 md:mb-32">
					<div className="space-y-8">
						<BlurbSphere
							title="Für wen ist das Website-Abo gedacht?"
							text="Unser Abo-Modell versteht sich als Website as a Service. Das bedeutet es richtet sich an alle Unternehmer da draußen, die sich NICHT mit der Technik und dem Webdesign beschäftigen wollen und sich auf ihr eigenes Geschäfts konzentrieren wollen. Die attraktive Rate und der monatliche Service sorgt dafür, dass wir immer als Ansprechpartner zur Verfügung stehen und Technik und Design jederzeit anpassen können."
							svg={<PencilIcon />}
						/>
						<BlurbSphere
							title="Wie lange dauert die Entwicklung?"
							svg={<EyeIcon />}
							text="Je nachdem wie fortgeschritten Dein Branding und Deine Inhalte sind, kann die Entwicklung stark variieren. Solltest Du mindestens schon ein Logo und die Inhalte für die Website vorbereitet haben, dann erhältst Du von uns die erste Revision nach nur zwei Wochen."
						/>
					</div>
					<BlurbSphere
						title="Worin unterscheidet sich das Website-Abo von der klassischen Auftragsarbeit?"
						svg={<EyeIcon />}
					>
						<P className="mb-3">
							Der langjährige Austausch mit Unternehmern und Kunden hat vor Augen geführt, dass das Thema Service und
							Kommunikation, bei vielen Auftragnehmern noch ein Problem ist. Wir mussten feststellen, dass Webseiten als
							Auftragsarbeiten ein großes Problem haben: nach Abschluss der Website, streicht der Programmierer seine
							Website faktisch aus dem Auftragsbuch und möchte sich auf das nächste Projekt konzentrieren und der
							Auftraggeber ist glücklich, dass seine neue Website online ist. Weder die eine noch die andere Seite fühlt
							sich aber diesen Moment für die Website verantwortlich. Sollten sich nun Änderungen ergeben, oder es zu
							technischen Änderungen kommen, die auf der Website umgesetzt werden müssen, fallen diese erst zu spät auf
							und es muss immer ein neuer Auftrag erteilt werden.
						</P>
						<P>
							Im Website-Abo fällt das Problem komplett weg. Wir fühlen uns dauerhaft für die Website verantwortlich und
							kümmern uns, dass die Website immer online steht und alle technisch und inhaltlich notwendigen Änderungen
							Aufwands-frei und schnell erledigt werden. Du erhältst von uns also ein komplettes Sorgenfrei-Paket und
							einen festen Ansprechpartner für alle Deine web spezifischen Fragen.
						</P>
					</BlurbSphere>
					<BlurbSphere title="Wie ist der Ablauf beim Website Abo?" svg={<EyeIcon />} className="md:col-span-2">
						<Grid cols={2}>
							<div>
								<P className="mb-3">
									Das Website-Abo lässt sich ganz einfach über diese Website anfragen. Wir werden Dich zeitnah
									zurückrufen und evaluieren mit Dir Dein Geschäftsmodell und was Du Dir von Deiner Website wünschst.
									Nach Gespräch mit unserem Vertrieb und der Entwicklung haben wir folgenden Stufenplan aufgebaut:
								</P>
							</div>
							<div>
								<ul className="list-decimal space-y-3 pl-3">
									<li className="list-item">
										<span className="font-bold">Inhalte & Funktionen:</span>
										<br />
										Du schickst uns mindestens eine Stichwort-Liste zu, mit den Inhalten, die Du benötigst und wir
										veranlassen die Produktion Deiner Inhalte.
									</li>
									<li className="list-item">
										<span className="font-bold">Konzeption & Webdesign:</span>
										<br />
										Wir erstellen grobe Wireframes und erstellen im Anschluss das Webdesign.
									</li>
									<li className="list-item">
										<span className="font-bold">Entwicklung:</span>
										<br />
										Wir beginnen mit der Entwicklung der Website anhand des Webdesigns.
									</li>
									<li className="list-item">
										<span className="font-bold">Revision:</span>
										<br />
										Du erhältst einen Projektlink und siehst, wie Deine fertige Website aussehen könnte. Nun passen wir
										noch die Kleinigkeiten an, die Du geändert haben möchtest.
									</li>
									<li className="list-item">
										<span className="font-bold">Live-Gang:</span>
										<br />
										Die Website wird mit Deinen Änderungen auf Deiner Wunschdomain veröffentlicht und bei Google
										registriert. Wir achten darauf dass, das Hosting einwandfrei funktioniert.
									</li>
								</ul>
							</div>
						</Grid>
					</BlurbSphere>
				</Grid>
				<Grid cols={2} className="mb-8 md:mb-32" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<H3 display className="mb-5 font-display">
							Häufige Fragen
						</H3>
						<P>
							Du hast noch eine Frage? Wir haben hier die häufigsten Fragen zusammengetragen und kurz beantwortet.
							Solltest Du trotzdem noch eine andere Frage haben, dann kontaktiere uns gerne dazu!
						</P>
					</div>
					<div className="bg-pattern p-3">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_faq.jpg"
							alt="Website Abo Häufige Fragen | Fragezeichen auf Holz"
							title="Website Abo Häufige Fragen | Fragezeichen auf Holz"
							placeholder="blurred"
							aspectRatio={16 / 9}
							transformOptions={{ grayscale: true }}
						/>
					</div>
				</Grid>
				<Grid cols={2} itemsStart>
					{faq.map((entry) => (
						<div key={entry.title}>
							<Divider className="mb-4" />
							<H4 className="mb-5 font-bold">{entry.title}</H4>
							{entry.questions.map((question) => (
								<dl key={question.title}>
									<FaqItem title={question.title.toString()} text={question.text.toString()} />
								</dl>
							))}
						</div>
					))}
				</Grid>
			</Container>
			<CtaSection
				title="Website Abo anfragen"
				text="Du bist überzeugt? Dann Fülle stelle uns gerne eine unverbindliche Anfrage und wir evaluieren wie wir Dir helfen können."
				button="Website Abo anfragen"
				onClick={() => navigate("/leistungen/website-abo/anfragen/")}
			/>
			<Container id="technik">
				<Grid cols={2} className="mb-16" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Die Technik ist entscheidend" number="03" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Unsere Technik
						</H2>
						<P>
							Monolithische Systeme wie Wordpress und Typo3 sind Systeme von gestern. Auf Basis des{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Modells
							</Link>{" "}
							bauen wir hoch skalierbare und schnelle Websites, die nicht von einzelner Software abhängig ist. In
							Kombination mit einer bequemen Entwicklungsumgebung können wir so Deine Website in kürzester Zeit
							realisieren.
						</P>
					</div>
					<div className="bg-pattern p-3">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_technik.jpg"
							alt="Website Abo Technik | Code auf einem Laptop"
							title="Website Abo Technik | Code auf einem Laptop"
							placeholder="blurred"
							aspectRatio={16 / 12}
							transformOptions={{ cropFocus: "south" }}
						/>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2} itemsCenter>
					<div className="md:order-2">
						<H3 className="mb-5 font-display">Gute Websites haben extrem kurze Ladezeiten</H3>
						<P>
							Gestaltung und Webdesign sind nicht alles. Die Geschwindigkeit hat einen essenziellen Einfluss auf die
							Benutzererfahrung und Kundengenerierung auf Deiner Website.{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Websites
							</Link>{" "}
							machen extrem schnelle Websites möglich, indem statische Assets vor dem Website Aufruf generiert wurden
							und so die Serverauslastung minimiert. So erreichen alle unsere Websites auf{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Basis
							</Link>{" "}
							einen Google Pagespeed von über 90 - das bedeutet, dass die Website meist unter einer halben Sekunde lädt.
						</P>
					</div>
					<div className="bg-pattern p-3 md:order-1">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_technik-performance.jpg"
							alt="Website Abo Technik | Code auf einem Laptop"
							title="Website Abo Technik | Code auf einem Laptop"
							placeholder="blurred"
							aspectRatio={16 / 8}
							transformOptions={{ cropFocus: "north" }}
						/>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2} itemsCenter>
					<div>
						<H3 className="mb-5 font-display">Einfache und günstige Skalierung</H3>
						<P>
							Mit{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Websites
							</Link>{" "}
							kann alles in einem Content Delivery Network zwischengespeichert werden. Das versichert einfacheren
							Bereitstellungen, integrierte Redundanzen und unglaubliche Belastbarkeit. Websites, welches nicht von
							einem zentralen Server abhängig ist, sichert Dich vor einem Serverausfall. Falls ein Server mal ausfällt,
							so springt ein weiter Server an dessen Stelle und liefert so die Website aus.
						</P>
					</div>
					<div className="bg-pattern p-3">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_technik-skalierung.jpg"
							alt="Website Abo Technik | Code auf einem Laptop"
							title="Website Abo Technik | Code auf einem Laptop"
							placeholder="blurred"
							aspectRatio={16 / 8}
						/>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2} itemsCenter>
					<div className="md:order-2">
						<H3 className="mb-5 font-display">Schnelle Ergebnisse</H3>
						<P>
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Websites
							</Link>{" "}
							können mit einer Vielzahl von Tools erstellt werden. Sie sind nicht von proprietären Technologien oder
							exotischen und wenig bekannten Frameworks abhängig. Stattdessen bauen sie auf weit verbreiteten Tools und
							Konventionen auf und nutzen Komponente statt Templates. Wir entwickeln echtes Webdesign auf Basis unserer
							Konzepte und machen uns unabhängig von Systemen. Unsere Kunden erfahren so extrem schnelle Änderungen und
							individuelle Webdesign-Lösungen, die nicht durch Technik oder Unwissenheit ausgebremst werden.
						</P>
					</div>
					<div className="bg-pattern p-3 md:order-1">
						<StaticImage
							src="../../../img/pages/leistungen/website-abo/website-abo_technik-ergebnisse.jpeg"
							alt="Website Abo Technik | Autobahn"
							title="Website Abo Technik | Autobahn"
							placeholder="blurred"
							aspectRatio={16 / 8}
						/>
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/website-abo/website-abo_header.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
