
import React from "react";

interface Props {
  children: React.ReactNode;
  as?: string;
  className?: string;
}

const H4: React.FC<Props> = ({ children, as = "h4", className }) => {
  const classes = `text-base md:text-xl ${className ? className : ""}`
  return React.createElement(as,  { className: classes }, children)
}

export default H4