import { CheckIcon, ChevronRightIcon, InformationCircleIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import React from "react";
import Button from "~components/common/Button";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

function PriceCardItemAlt({ name, description, price, onClick, link, features, monthly, contract }) {
	return (
		<div className="bg-pattern p-2">
			<div className="bg-white p-3">
				<H3>{name}</H3>
				<P className="mt-4 text-sm" style={{ minHeight: "4rem" }}>
					{description}
				</P>
				<P className="my-4">
					<span className="text-4xl font-bold leading-none">
						{price},- € <span className="text-sm font-normal text-gray-700">/ netto {monthly && "pro Monat"}</span>
					</span>
				</P>
				<Button onClick={onClick} to={link} text={`${name} anfragen`} className="w-full" />
			</div>
			<div className="bg-white p-3">
				{contract && (
					<>
						<span className="text-xs font-bold uppercase">Vertragliches:</span>
						<ul className="my-6 space-y-4">
							{contract.map((entry) => (
								<li className="flex space-x-3" key={entry}>
									<ChevronRightIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
									<span className="text-sm">{entry}</span>
								</li>
							))}
						</ul>
					</>
				)}
				<span className="text-xs font-bold uppercase">{name} beinhaltet folgende Leistung</span>
				<ul className="mt-6 space-y-4">
					{features.map((feature) => (
						<li key={feature} className="flex space-x-3">
							<CheckIcon className="h-5 w-5 shrink-0 text-green-500" aria-hidden="true" />
							<span className="text-sm ">{feature}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default PriceCardItemAlt;

PriceCardItemAlt.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	onClick: PropTypes.func,
	link: PropTypes.string,
	features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	monthly: PropTypes.bool,
	contract: PropTypes.arrayOf(PropTypes.string.isRequired),
};

PriceCardItemAlt.defaultProps = {
	monthly: false,
	contract: null,
	onClick: null,
	link: null,
};
