import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import React from "react";

export default function FaqItem({ text, title }) {
	return (
		<Disclosure>
			{({ open }) => (
				<div className="p-2 bg-pattern">
					<Disclosure.Button className="w-full bg-white focus:outline-none focus:ring-1 focus:ring-black focus:ring-offset-1">
						<div className="flex items-start justify-between w-full p-3 text-left">
							<dt className="font-bold">{title}</dt>
							<span className="flex items-center ml-6 h-7">
								<ChevronRightIcon className={`${open ? "rotate-90" : "rotate-0"} h-6 w-6`} />
							</span>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel as="dd" className="p-3 leading-relaxed text-gray-900 bg-white" unmount={false}>
						{text}
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	);
}

FaqItem.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};
